import React from "react";
import { Helmet } from "react-helmet";

import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import SchemaOrg from "./schema-org";
import { firstMatch, isDefined, isUndefined, truncateText } from "../../util";

function SEO({ siteMetadata, postData, isBlogPost }) {
  const title = [truncateText(postData.title, 50, "..."), siteMetadata.title]
    .filter(isDefined)
    .join(" | ");

  const description = firstMatch("description", [postData, siteMetadata]);
  const { image, datePublished } = postData;

  debugger;
  const url = [siteMetadata.canonicalUrl, postData.slug]
    .filter(isDefined)
    .join("");

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={firstMatch("title", [postData, siteMetadata])}
        />
        <meta name="image" content={image} />

        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        {isBlogPost ? <meta property="og:type" content="article" /> : null}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        {/*<meta property="fb:app_id" content={seo.social.fbAppID} />*/}

        {/*/!* Twitter Card tags *!/*/}
        <meta name="twitter:card" content="summary_large_image" />
        {/*<meta name="twitter:creator" content={seo.social.twitter} />*/}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <SchemaOrg
        isBlogPost={isBlogPost}
        url={url}
        title={title}
        image={image}
        description={description}
        datePublished={datePublished}
        canonicalUrl={siteMetadata.canonicalUrl}
        defaultTitle={title}
      />
    </>
  );
}

function SEOWithQuery(props) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            description
            canonicalUrl
          }
        }
      }
    `
  );
  return <SEO siteMetadata={siteMetadata} {...props} />;
}

SEOWithQuery.propTypes = {
  postData: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    datePublished: PropTypes.instanceOf(Date),
    slug: PropTypes.string,
  }),
};

SEOWithQuery.defaultProps = {
  postData: {
    title: undefined,
    image: undefined,
    slug: undefined,
    datePublished: undefined,
  },
  isBlogPost: false,
};

export default SEOWithQuery;

import React from "react";
import { graphql, navigate } from "gatsby";
import Layout from "../components/layout";
import { format } from "date-fns";
import ReactPlayer from "react-player";
import styles from "./instagram-post.module.css";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import Header from "../components/header";
import SEO from "../components/seo/seo";

export default function InstagramPost({ data, pageContext }) {
  const post = data.postsJson;
  const posts = data.allPostsJson.edges;

  const postIndex = findIndex(
    posts,
    post => post.node.fields.slug === pageContext.slug
  );

  const futurePost =
    postIndex < posts.length - 1
      ? get(
          posts[Math.min(postIndex + 1, posts.length - 1)],
          "node.fields.slug"
        )
      : undefined;

  const pastPost =
    postIndex > 0
      ? get(posts[Math.max(0, postIndex - 1)], "node.fields.slug")
      : undefined;

  const date = new Date(post.time);
  return (
    <Layout>
      <SEO
        postData={{
          title: post.text,
          description: post.text,
          image: post.media[0].image.publicURL,
          datePublished: date,
          slug: post.fields.slug,
        }}
      />
      <Header headerText={`Instagram`} />
      <div>
        <div>
          {futurePost ? (
            <FaCaretLeft
              className={styles.caretLeft}
              onClick={() => navigate(futurePost)}
            />
          ) : null}
          {pastPost ? (
            <FaCaretRight
              className={styles.caretRight}
              onClick={() => navigate(pastPost)}
            />
          ) : null}
        </div>
        <p>{post.text}</p>
        {post.media.map((item, idx) => (
          <div key={`post.id-${idx}`} className={styles.post}>
            {item.is_video ? (
              <div className={styles.playerWrapper}>
                <ReactPlayer
                  url={item.video.publicURL}
                  playing={false}
                  controls={true}
                  light={item.image.publicURL}
                  width="100%"
                  height="100%"
                  className={styles.reactPlayer}
                />
              </div>
            ) : (
              <img src={item.image.publicURL} alt={item.shortcode} />
            )}
          </div>
        ))}
        <p>
          <em>
            posted by @{post.username} on {format(date, "d MMM yyyy h:mm a")}
          </em>
        </p>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($id: String!) {
    postsJson(id: { eq: $id }) {
      id
      text
      time
      username
      shortcode
      fields {
        slug
      }
      media {
        image {
          publicURL
        }
        is_video
        video {
          publicURL
        }
      }
      avatar
    }
    allPostsJson(sort: { fields: [time], order: DESC }) {
      edges {
        node {
          id
          shortcode
          fields {
            slug
          }
        }
      }
      totalCount
    }
  }
`;

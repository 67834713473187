/**
 * firstMatch looks through a list of objects (in order) and returns a value from the first object that has a matching key
 * @param key
 * @param {Array<Object>} searchObjects
 * @param defaultValue
 */

export function firstMatch(key, searchObjects = [], defaultValue = undefined) {
  for (let i = 0; i < searchObjects.length; i += 1) {
    const haystack = searchObjects[i];
    if (
      typeof haystack === "object" &&
      haystack !== null &&
      isDefined(haystack[key])
    ) {
      return haystack[key];
    }
  }
  return defaultValue;
}

export function isUndefined(value) {
  return typeof value === "undefined";
}

export function isDefined(value) {
  return !isUndefined(value);
}

export function truncateText(
  stringValue,
  maxLength,
  overflowIndicator = "&hellip;",
  useWordBoundary = true
) {
  if (isUndefined(stringValue) || stringValue.length <= maxLength) {
    return stringValue;
  }

  const subString = stringValue.substr(0, maxLength - 1);

  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString) + overflowIndicator
  );
}
